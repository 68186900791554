.home-page-wrapper {
  width: 60%;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
  justify-self: center;
}

@media (max-width: 768px) {
  .home-page-wrapper {
    width: 100%;
    justify-self: auto;
  }
}
