/* .organitzacio-apart__container {
} */

.organitzacio-apart__header {
  height: 250px;
  width: 100%;
  background-color: #003a83;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  margin-bottom: 70px;
}

.honor {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.honor-wrapper {
  display: flex;
  justify-content: space-between;
  width: 85%;
}

.organitzacio-apart__header p {
  color: #fff;
  font-family: "Aptos", sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.organitzacio-apart__wrapper {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
}
.organitzacio-apart__wrapper-item__title {
  color: #003a83;
  font-family: "Aptos", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.title {
  padding-top: 25px;
}

.wrapper-item-border {
  padding-bottom: 25px;
  border-bottom: 1px solid #c2c2c2;
}
.organitzacio-apart__wrapper-item__subtitle {
  color: #000;
  font-family: "Aptos", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.organitzacio-apart__wrapper-item__list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #000;
  font-family: "Aptos", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
}

@media screen and (max-width: 795px) {
  .organitzacio-apart__header {
    height: 150px;
  }
  .organitzacio-apart__wrapper {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
  }
  .organitzacio-apart__wrapper-item__subtitle {
    font-size: 22px;
  }
  .organitzacio-apart__header p {
    font-size: 36px;
  }

  .honor-wrapper {
    flex-direction: column;
  }
}
