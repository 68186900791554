.colaboradors__container {
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.colaboradors__item-wrapper {
  width: calc(100% - 3rem);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.colaboradors__item {
  max-height: 180px;
  max-width: 180px;
}
@media screen and (max-width: 768px) {
  .colaboradors__item-wrapper {
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
  }
}
