.home__container {
  height: calc(100vh - 70px);
  width: calc(100% - 48%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24%;
  padding-right: 24%;
  position: relative;
  padding-top: 70px;
}

.home__more-info {
  color: #fff;
  font-family: "Aptos" sans-serif;
  font-size: 22px;
}

.home__more-info:hover {
  cursor: pointer;
}

.home__header {
  color: #fff;
  font-family: "Aptos", sans-serif;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 65px; /* 120.37% */
  margin: 0;
}

.home__welcome-text {
  color: #003a83;
  font-family: "Aptos", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 138.889% */
  letter-spacing: 0.18px;
  margin-top: 0;
  padding-left: 15%;
  padding-right: 10%;
  text-align: justify;
}


.video-container{
  height:600px
}

@media screen and (max-width: 795px) {
  .home__container {
    padding: 40px;
    width: auto;
    margin-top: 150px;
  }
  .home__welcome-text {
    padding: 0;
  }
}

